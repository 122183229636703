import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../Assets/images/logo.png";
import KeyboardArrowRightTwoToneIcon from "@mui/icons-material/KeyboardArrowRightTwoTone";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import toasted from "../config/toast";

const Header = () => {


  const [RightSIdebar, setRightSIdebar] = useState(false);
  const onOpenRightSIdebar = () => setRightSIdebar(true);
  const onCloseRightSIdebar = () => setRightSIdebar(false);

  const navigate = useNavigate();
  let location = useLocation();

  const togglerBar = () => {
    const a: HTMLElement | any = document.body;
    a.classList.toggle("show_header__mobail");
  };

  const togglerBarRemove = () => {
    const aRemove: HTMLElement | any = document.body;
    aRemove.classList.remove("show_header__mobail");
  };

  useEffect(() => {

    // if (auth === "true") {
    (async () => {

      let res = await _fetch(`${api_url}home/dashboard`, "GET", {})
      if (res?.status === "success") {
      }
      else {
        localStorage.clear();
        navigate("/login");
      }
    })();
    // }
  }, [location.pathname]);


  const Logout = async () => {

    var data = await _fetch(`${api_url}auth/logout`, 'GET', {});
    if (data?.message === 'You are not logged in! Please log in to get access.') {
      // toasted.success(data?.message);
      localStorage.clear();
      window.location.reload();
      navigate('/login');
    } else {
      toasted.error(data?.message);
    }
  }

  return (
    <>
      <div className="header-sec">
        <div className="coustom_container">
          <div className="inner-sec">
            <div className="left">
              <div className="logo">
                <Link to="/dashboard">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="links">
                <Link to="/dashboard" className="nav_links active">
                  Dashboard
                </Link>

                <div className="dropdown">
                  <div className="nav_links">Participants</div>

                  <div className="dropdown-menu">
                    <Link to="/user">
                      All Participants
                    </Link>
                    <Link to="/today-activate">
                      Today Active Participants
                    </Link>
                    <Link to="/paidUsers">
                      Active Participants
                    </Link>

                    <Link to="/inactiveUsers">
                      Inactive Participants
                    </Link>

                    <Link to="/blockedUsers">
                      Blocked Participants
                    </Link>
                    <Link to="/usersByRank">
                      Ranked Users
                    </Link>
                    {/* <Link to="/acheiverUsers">
                      2024 Summit Achiever
                    </Link>
                    <Link to="/archiever-history">
                      Expo Archievers
                    </Link> */}
                    <Link to="/corearchiever-history/1">
                      Starter Archievers
                    </Link>
                    <Link to="/corearchiever-history/2">
                      Grow Archievers
                    </Link>
                    <Link to="/corearchiever-history/3">
                      Main Archievers
                    </Link>

                  </div>
                </div>

                <div className="dropdown">
                  <div className="nav_links">Settings</div>
                  <div className="dropdown-menu">
                    <Link to="/news">
                      News
                    </Link>
                    <Link to="/popup_upload">
                      User Popup Banner
                    </Link>
                    <Link to="/roiList">
                      ROI List
                    </Link>

                    <Link to="/withdrawsetting">
                      Settings
                    </Link>
                    <Link to="/price-setting">
                      Update Token Price
                    </Link>
                    <Link to="/down-level">
                      Level Bussiness
                    </Link>
                    <Link to="/users-business">
                      Total Bussiness
                    </Link>
                    {/* <Link to="/almatery-users">
                    Almatery Users 
                    </Link> */}
                    <Link to="/stake-history">
                      Stake History
                    </Link>
                    <Link to="/unstake-history">
                      ReStake History
                    </Link>

                    {/* <Link to="/WithdrawSettings">
                      Site Income Settings <KeyboardArrowRightTwoToneIcon />
                    </Link> */}
                  </div>
                </div>

                <div className="dropdown">
                  <div className="nav_links">Income</div>

                  <div className="dropdown-menu">

                    <Link to="/income/staking_incentive">
                      Staking Incentive
                    </Link>

                    <Link to="/income/direct_business_incentive">
                      Direct Business Incentive
                    </Link>
                    <Link to="/income/level_business_incentive">
                      Level Business Incentive
                    </Link>

                    <Link to="/income/royality_incentive">
                      Royality Incentive
                    </Link>

                    <Link to="/income/permanent_reward">
                      Permanent Reward
                    </Link>

                    <Link to="/income/difference_business_incentive">
                      Difference Business Incentive
                    </Link>

                    <Link to="/BusinessHistory">
                      Business History
                    </Link>
                    {/* <Link to="/RankedUsers">
                      Reward Users
                    </Link> */}
                    <Link to="/allincomes">
                      All Incomes
                    </Link>
                    <Link to="/coreusers-history">
                    Core Income Users
                    </Link>
                  </div>
                </div>

                <div className="dropdown">
                  <div className="nav_links">Credit/Debit</div>
                  <div className="dropdown-menu">
                    <Link to="/credit-debit">
                      Credit/Debit
                    </Link>
                    <Link to="/adminTrxHistory">
                      History
                    </Link>
                  </div>
                </div>

                <div className="dropdown">
                  <div className="nav_links">Market</div>

                  <div className="dropdown-menu">
                    <li className="dropdown_item pt-0"><Link to="/buyhistory">Buy Details</Link></li>
                    <li className="dropdown_item drp-item">
                      Sell <KeyboardArrowRightTwoToneIcon />
                      <div className="dropdown_menu2">
                        {/* <Link to="/ordersDetails">
                          Pending Order Details
                        </Link> */}
                        {/* <Link to="/sellOrdersDetails">
                          Pending Order Details
                        </Link> */}
                        <Link to="/approvedOrdersDetails">
                          Approved Order Details
                        </Link>
                        {/* <Link to="/rejectOrderDetails">
                          Rejected Order Details
                        </Link> */}
                      </div>
                    </li>

                  </div>
                </div>


                <div className="dropdown">
                  <div className="nav_links">Withdraw</div>

                  <div className="dropdown-menu">
                    <Link to="/Withdraw">
                      Withdraw Request
                    </Link>
                    <Link to="/Approved">
                      Approved Withdraw Request{" "}
                    </Link>
                    <Link to="/Pending">
                      Pending Withdraw Request
                    </Link>
                    <Link to="/Rejected">
                      Rejected Withdraw Request
                    </Link>

                    <Link to="/adminpending">
                      Admin Pending Payouts
                    </Link>
                  </div>
                </div>

                <div className="dropdown">
                  <div className="nav_links">Mail</div>

                  <div className="dropdown-menu">
                    <Link to="/pending-tickets">
                      Pending Ticket
                    </Link>
                    <Link to="/solved-ticket">
                      Solved Ticket
                    </Link>
                  </div>
                </div>

                <div className="dropdown">
                  <div className="nav_links">Deposit</div>
                  <div className="dropdown-menu">
                    <Link to="/SendWallet">
                      Fund Distribution
                    </Link>
                    {/* <Link to="/SendIncomeWallet">
                      Send Credit Fund <KeyboardArrowRightTwoToneIcon />
                    </Link> */}
                    <Link to="/fund_history">
                      Fund History
                    </Link>
                  </div>
                </div>

              </div>
            </div>
            <div className="right_main">

              <div className="logout_btn">
                <button type="submit" className="main_button" onClick={Logout}>
                  Logout
                </button>
              </div>
              <div className="bar___pvt" onClick={() => { onOpenRightSIdebar(); togglerBar(); }} >
                <span className="line-toggle"></span>
                <span className="line-toggle"></span>
                <span className="line-toggle"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={RightSIdebar}
        onClose={() => {
          onCloseRightSIdebar();
          togglerBarRemove();
        }}
        classNames={{
          overlay: "P2P-PayOverlay",
          modal: "Header__sidebar__right",
          modalAnimationIn: "customEnterModalAnimation",
          modalAnimationOut: "customLeaveModalAnimation",
        }}
        animationDuration={800}
      >
        <div className="right_header-sidebar">
          <div className="">
            <h4>More services</h4>
          </div>
          <div className="inner_links">
            <Link className="Responsive_link" to="/dashboard">
              Dashboard
            </Link>

            <Accordion className="mob_accordian">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon />
                  Participants
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/user">
                      <li> All Participants </li>
                    </Link>
                    <Link to="/today-activate ">
                      <li> Today Active Participants</li>
                    </Link>
                    <Link to="/paidUsers ">
                      <li> Active Participants</li>
                    </Link>
                    <Link to="/inactiveUsers">
                      <li>Inactive Participants</li>
                    </Link>

                    <Link to="/blockedUsers">
                      <li>Blocked Participants</li>
                    </Link>
                    <Link to="/usersByRank">
                      <li>Ranked Users</li>
                    </Link>
                    {/* <Link to="/acheiverUsers">
                      <li>2024 Summit Achiever</li>
                    </Link>
                    <Link to="/archiever-history">
                      <li>Expo Achievers</li>
                    </Link> */}

                    <Link to="/corearchiever-history/1">
                      <li>Starter Archievers</li>
                    </Link>

                    <Link to="/corearchiever-history/2">
                      <li>Grow Archievers</li>
                    </Link>

                    <Link to="/corearchiever-history/3">
                      <li>Main Archievers</li>
                    </Link>

                    {/* <Link to="/usersByRank">
                      <li>Ranked Users</li>
                    </Link> */}

                    {/* <Link to="/acheiverUsers">
                      <li>2024 Summit Achiever</li>
                    </Link> */}

                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Settings
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/news ">
                      <li>News</li>
                    </Link>
                    <Link to="/popup_upload">
                      <li>User Popup Banner</li>
                    </Link>
                    <Link to="/roiList">
                      <li>ROI List</li>
                    </Link>

                    <Link to="/withdrawsetting">
                      <li> Settings</li>
                    </Link>

                    <Link to="/price-setting">
                      <li> Update Token Price</li>
                    </Link>
                    <Link to="/down-level">
                      <li>Level Bussiness</li>
                    </Link>
                    <Link to="/users-business">
                      <li>Total Bussiness</li>
                    </Link>

                    {/* <Link to="/almatery-users">
                    <li> Almatery Users</li>
                    </Link> */}

                    <Link to="/stake-history">
                      <li> Stake History</li>
                    </Link>

                    <Link to="/unstake-history">
                      <li> ReStake History</li>
                    </Link>



                    {/* <Link to="/WithdrawSettings">
                      <li>Site Income Settings</li>
                    </Link> */}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Income
                </Accordion.Header>
                <Accordion.Body className="pt-0">
                  <ul>

                    <Link to="/income/staking_incentive">
                      <li>Staking Incentive</li>
                    </Link>
                    <Link to="/income/direct_business_incentive">
                      <li>Direct Business Incentive</li>
                    </Link>
                    <Link to="/income/level_business_incentive">
                      <li>Level Business Incentive</li>
                    </Link>

                    <Link to="/income/royality_incentive">
                      <li>Royality Incentive</li>
                    </Link>

                    <Link to="/income/permanent_reward">
                      <li>Permanent Reward</li>
                    </Link>

                    <Link to="/income/difference_business_incentive">
                      <li>Difference Business Incentive</li>
                    </Link>
                    <Link to="/BusinessHistory">
                      <li>Business History </li>
                    </Link>
                    {/* <Link to="/RankedUsers">
                      <li>Reward Users </li>
                    </Link> */}

                    <Link to="/BusinessHistory">
                      <li>Business History</li>
                    </Link>
                    {/* <Link to="/RankedUsers">
                      <li>Reward Users</li>
                    </Link> */}

                    <Link to="/allincomes">
                      <li>All Incomes</li>
                    </Link>

                    <Link to="/coreusers-history">
                      <li> Core Income Users</li>
                    </Link>

                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Credit/Debit
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/credit-debit">
                      <li>Credit/Debit</li>
                    </Link>
                    <Link to="/adminTrxHistory">
                      <li>Credit/Debit History</li>
                    </Link>

                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Market
                </Accordion.Header>
                <Accordion.Body className="pt-0">
                  <ul>
                    <Link className="p-2" to="/buyhistory">
                      Buy Order Details
                    </Link>
                  </ul>
                  <ul>
                    <Accordion className="">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <KeyboardArrowRightTwoToneIcon />
                          Sell
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            {/* <Link to="/ordersDetails">
                              <li> Pending Order Details</li>
                            </Link> */}
                            {/* <Link to="/sellOrdersDetails">
                              <li> Pending Order Details</li>
                            </Link> */}
                            <Link to="/approvedOrdersDetails">
                              <li>Approved Order Details</li>
                            </Link>
                            {/* <Link to="/rejectOrderDetails">
                              <li>Rejected Order Details</li>
                            </Link> */}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>


              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Withdraw
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/Withdraw">
                      <li>Withdraw Request</li>
                    </Link>
                    <Link to="/Approved">
                      <li>Approved Withdraw Request</li>
                    </Link>
                    <Link to="/Pending">
                      <li>Pending Withdraw Request</li>
                    </Link>
                    <Link to="/Rejected">
                      <li>Rejected Withdraw Request</li>
                    </Link>

                    <Link to="/adminpending">
                      <li>Admin Pending Payouts</li>
                    </Link>

                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Mail
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/pending-tickets">
                      <li>Pending Ticket</li>
                    </Link>
                    <Link to="/solved-ticket">
                      <li>Solved Ticket </li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Deposit
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/SendWallet">
                      <li> Fund Distribution </li>
                    </Link>

                    {/* <Link to="/SendIncomeWallet">
                      <li>Send Credit Fund </li>
                    </Link> */}
                    <Link to="/fund_history">
                      <li> Fund History  </li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

          </div>

        </div>
      </Modal>
    </>
  );
};

export default Header;
