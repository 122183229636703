//OrderDetails

import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import LoadingBar from 'react-top-loading-bar'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import toasted from "../config/toast";
import { FiberManualRecord, PersonRounded, AttachMoneyRounded, } from "@mui/icons-material";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";

function BuyDetails() {

    const [isOrderDetails, setOrderDetails] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('-created_at');
    const [limit, setLimit] = useState(10);
    const [keyCount, setKeycount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [search, setSearch] = useState('');
    const [progress, setProgress] = useState(0);
    const [isTrue, setIsTrue] = useState(true);
    const [todayTotal, setTodayTotal] = useState(0);
    const [yesterdayTotal, setYesterdayTotal] = useState(0);

    useEffect(() => {
        getOdersDetails();
    }, [page, limit]);

    const getOdersDetails = async () => {

        setIsTrue(true);
        setProgress(30);
        let res = await _fetch(`${api_url}report/buyorders?sort=${sort}&page=${page}&limit=${limit}`, "GET", {});
        if (res?.status === "success") {
            setOrderDetails(res?.data);
            setTotalRecords(res?.total);
            
            setTodayTotal(res?.todayTotal);
            setYesterdayTotal(res?.yesterdayTotal);
            setProgress(100);
            setIsTrue(false);
        }
        if (page > 1) {
            setKeycount(0);
            var count = page - 1;
            setKeycount(count * limit);
        }
    }

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setPage(newOffset);
    };

    function searchSubmit() {
        setSearch(searchValue);
    }

    return (
        <>

            <LoadingBar
                color='var(--primary-color)'
                progress={progress}
                containerClassName="LoadingBar_contain"
                className="loaderbar-s"
                onLoaderFinished={() => setProgress(0)}
            />
            <div className={isTrue ? 'loader-bg' : ''}></div>

            <div className="in-table pb-4">
                <div className="top-simple-heading">
                    <h1 className="heading-h1"> Buy Order Details</h1>
                    {/* <div className="top-search">
                        <form action="">
                            <div className="top-sech">
                                <input
                                    className="input-fields"
                                    type="search"
                                    placeholder="Search Fund History "
                                    onChange={(e: any) => setSearchValue(e.target.value)}
                                    value={searchValue}
                                />
                                <button type="submit" className="search-bs" onClick={searchSubmit}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div> */}
                    <div className="appro">
                        <div className="coustom_container">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                    <div className="card table-details">
                                        <div className="card-header">
                                            <div className="for-card-flex">
                                                <div className="card-imgs">
                                                    <img src={card_1} alt="icon-bg" />
                                                    <AttachMoneyRounded className="icon user for-custom-money" />
                                                </div>
                                                <p className="text-sm mb-0 text-capitalize">
                                                    Today Total Amount
                                                </p>
                                            </div>
                                            <div className="text-start pt-1">
                                                <h4 className="active-txt"> <span className="de-sc"></span> {todayTotal} <span className="users-txt"></span></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-6">
                                    <div className="card table-details">
                                        <div className="card-header">
                                            <div className="for-card-flex">
                                                <div className="card-imgs">
                                                    <img src={card_1} alt="icon-bg" />
                                                    <AttachMoneyRounded className="icon user for-custom-money" />
                                                </div>
                                                <p className="text-sm mb-0 text-capitalize">
                                                    Yesterday Total Amount
                                                </p>
                                            </div>
                                            <div className="text-start pt-1">
                                                <h4 className="active-txt"> <span className="de-sc"></span> {yesterdayTotal} <span className="users-txt"></span></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="coustom_container">
                    <div className="table_main table_main_comun">

                        <div className="outer-table table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">ROI</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isOrderDetails?.map((item: any, index: any) => {

                                        return (

                                            <tr key={index}>
                                                <td>{page > 1 ? index + keyCount + 1 : index + 1}</td>
                                                <td>{item?.username}</td>
                                                <td>{item?.name != 'null null' && item?.name ? item?.name : '--'}</td>
                                                <td>{item?.amount}</td>
                                                <td>{(item?.roi)?.toFixed(4)}</td>
                                                <td>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={1}
                                pageCount={totalRecords / 10}
                                previousLabel="<"
                            />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BuyDetails;