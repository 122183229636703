import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import _fetch from '../config/api';
import { api_url } from '../config/config';
import toasted from '../config/toast';

const CreditDebit = () => {
    // State variables for input fields
    const [userId, setUserId] = useState('');
    // const [amount, setAmount] = useState();
    const [amount, setAmount] = useState<number | undefined>(undefined);
    const [incomeType, setIncomeType] = useState('Referal Bonus');
    const [transactionType, setTransactionType] = useState('Credit Income');

    // Function to handle form submission
    const handleSubmit = async (e: any) => {
        e.preventDefault();

        // Data to be sent to the backend
        const data = {
            username: userId,
            amount: amount,
            incomeType: incomeType,
            transactionType: transactionType,
        };
        let res = await _fetch(`${api_url}credit/creditDebit`, "POST", data, {});
        if (res?.status == 'success') {
            toasted.success(res?.message);
        }
        else {
            toasted.error(res?.message);
        }
    };

    return (
        <div className='credit-debit'>
            <Container>
                <h2>Credit/Debit</h2>
                <form onSubmit={handleSubmit}>
                    <label>Username</label>
                    <input
                        type="text"
                        placeholder="Username"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                        required
                    />

                    <label>Amount</label>
                    <input
                        type="number"
                        placeholder="Amount"
                        value={amount}
                        onChange={(e: any) => setAmount(parseInt(e.target.value))}
                        required
                    />

                    <label>Income Type</label><br />
                    <select
                        value={incomeType}
                        onChange={(e) => setIncomeType(e.target.value)}
                    >
                        <option value="">Select Income Type</option>
                        <option value="staking_incentive">Staking Incentive </option>
                        <option value="direct_business_incentive">Direct Business Incentive</option>
                        <option value="level_business_incentive">Level Business Incentive</option>
                        <option value="royality_incentive">Royality Incentive</option>
                        <option value="permanent_reward">Permanent Reward</option>
                        <option value="difference_business_incentive">Difference Business Incentive </option>
                    </select><br />

                    <label>Type</label><br />
                    <select
                        value={transactionType}
                        onChange={(e) => setTransactionType(e.target.value)}
                    >
                        <option value="">Please Select Type</option>
                        <option value="1">Credit Income</option>
                        <option value="2">Debit Income</option>
                    </select><br />

                    <button className='main_button' type='submit'>Send</button>
                </form>
            </Container>
        </div>
    );
};

export default CreditDebit;
