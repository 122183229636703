//OrderDetails

import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import LoadingBar from 'react-top-loading-bar'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import toasted from "../config/toast";

function SellOrderDetails() {
    const [isOrderDetails, setOrderDetails] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState('-created_at');
    const [limit, setLimit] = useState(10);
    const [keyCount, setKeycount] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [search, setSearch] = useState('');
    const [progress, setProgress] = useState(0);
    const [isTrue, setIsTrue] = useState(true);

    useEffect(() => {
        getOdersDetails();
    }, [page, limit]);

    const getOdersDetails = async () => {

        setIsTrue(true);
        setProgress(30);
        let res = await _fetch(`${api_url}biddings/get-orders?type=Pending&sort=${sort}&page=${page}&limit=${limit}`, "GET", {})
        if (res?.status === "success") {
            // setProgress(50);
            setOrderDetails(res?.data);
            setTotalRecords(res?.total);
            setProgress(100);
            setIsTrue(false);

        }
        if (page > 1) {
            setKeycount(0);
            var count = page - 1;
            setKeycount(count * limit);
        }
    }

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const [userId, setUserId] = useState('');
    const [userHash, setUserHash] = useState('');

    const statusAproved = async () => {
        let statusData = {
            userId: userId,
            hash: userHash
        }
        let res = await _fetch(`${api_url}biddings/status-approved`, "POST", statusData);
        if (res?.status === 'success') {
            toasted.success(res?.message);
            getOdersDetails();
            onCloseModal()
        }

    }
    const statusReject = async (userId: any) => {
        let statusData = {
            userId: userId,
        }
        let res = await _fetch(`${api_url}biddings/status-rejected`, "POST", statusData);
        if (res?.status === 'success') {
            toasted.success(res?.message);
            getOdersDetails();
            onCloseModal()
        }
    }

    const handlePageClick = async (event: any) => {
        const newOffset = (event.selected + 1);
        setPage(newOffset);
    };

    return (
        <>
            <LoadingBar
                color='var(--primary-color)'
                progress={progress}
                containerClassName="LoadingBar_contain"
                className="loaderbar-s"
                onLoaderFinished={() => setProgress(0)}
            />
            <div className={isTrue ? 'loader-bg' : ''}></div>

            <div className="in-table pb-4">
                <div className="top-simple-heading">
                    <h1 className="heading-h1">Pending Order Details</h1>
                    {/* <div className="top-search">
                        <form action="">
                            <div className="top-sech">
                                <input
                                    className="input-fields"
                                    type="search"
                                    placeholder="Search Fund History "
                                    onChange={(e: any) => setSearchValue(e.target.value)}
                                    value={searchValue}
                                />
                                <button type="submit" className="search-bs" onClick={searchSubmit}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div> */}
                </div>

                <div className="coustom_container">
                    <div className="table_main table_main_comun">

                        <div className="outer-table table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Action</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">User ID</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Hash</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isOrderDetails?.map((item: any, index: any) => {

                                        return (

                                            <tr key={index}>
                                                <td>{page > 1 ? index + keyCount + 1 : index + 1}</td>
                                                <td>
                                                    <div className="btns_flex">
                                                        <button className="view-button btn btn-success" onClick={() => { setUserId(item?._id); onOpenModal() }}>Approve</button>
                                                        <button className="view-button btn btn-danger" onClick={() => statusReject(item?._id)}>Reject</button>
                                                    </div>

                                                </td>
                                                <td>{item?.status}</td>
                                                <td>{item?.username}</td>
                                                <td>{item?.name != 'null null' && item?.name ? item?.name : '--'}</td>
                                                <td>{item?.amount}</td>
                                                <td>{(item?.eth_address)}</td>
                                                {/* <td>{item?.hash}</td> */}
                                                <td>{dateformat(item?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={1}
                                pageCount={totalRecords / 10}
                                previousLabel="<"
                            />}
                        </div>
                    </div>
                </div>

            </div>
            <Modal
                classNames={{
                    modal: "order_detail_modal",
                }}
                open={open} onClose={onCloseModal} center>
                <div className="inner_data">
                    <h5>Proccessing Request</h5>
                    <input type="text" placeholder="Hash" onChange={(event: any) => { setUserHash(event.target.value) }} />
                    <div className="modal_btns">
                        <button className="approve-btn btn btn-success" onClick={() => { statusAproved(); }}>Approve</button>

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default SellOrderDetails