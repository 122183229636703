import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import { FiberManualRecord, PersonRounded } from "@mui/icons-material";
import LoadingBar from 'react-top-loading-bar';
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import dateformat from "dateformat";

const AdminFundHistory = () => {

  const [allDetail, setAllDetail] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [keyCount, setKeycount] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const [searchedValue, setSearchedValue] = useState("");
  const [searchedType, setSearchedType] = useState("username");

  useEffect(() => {
    getData();
  }, [searchedType, searchedValue, limit, page]);

  async function getData() {

    setIsTrue(true);
    setProgress(10);

    const Data = await _fetch(`${api_url}report/creditDebitHistory?page=${page}&limit=${limit}`, 'GET', {});

    if (Data?.status === "success") {
      setLoading(false);
      setAllDetail(Data?.data);
      setTotalData(Data?.total);
      setProgress(100);
      setIsTrue(false);
    }
    if (page > 1) {
      setKeycount(0);
      var count = page - 1;
      setKeycount(count * limit);
    }
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setPage(newOffset);
  };

  return (
    <>
      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="table-sec pb-4">
        <div className="table-sec-subheader">
          <div className="page_info">
            <div className="coustom_container">
              <div className="d-flex align-items-center justify-content-center">

                <h6> <PersonRounded /> Fund History </h6>
                <div className="inner_links_header">

                  <FiberManualRecord className="dot" />
                  <a href="#">Home</a>
                </div>
              </div>
            </div>
          </div>

          {/* user details...  */}

          <div className="details-sec">
            {/* <div className="coustom_container">
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        All Participants
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="active-txt"> <span className="de-sc"></span> {allDetail?.totalUsers} <span className="users-txt">Users</span></h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Active Participants
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="text-success"> <span className="de-sc"></span> {allDetail?.activeUsers} <span className="users-txt">Users</span></h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Inactive Participants
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="text-warning"> <span className="de-sc"></span> {allDetail?.inactiveUsers} <span className="users-txt">Users</span></h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Blocked Participants
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="text-danger"> <span className="de-sc"></span> {allDetail?.blockUsers} <span className="users-txt">Users</span></h4>
                    </div>
                  </div>
                </div>

              </div>
            </div> */}
          </div>
        </div>

        <div className="coustom_container mb-5">
          <div className="table_main table_main_new">
            <div className="heading">
              <h1>Fund History</h1>
            </div>
            <div className="table-sec-new">
              <div className="outer-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Username</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Income Type</th>
                      <th scope="col">Type</th>
                      <th scope="col">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allDetail?.map((val: any, key: any) => {
                      return (
                        <tr>
                          <td>{page > 1 ? key + keyCount + 1 : key + 1}</td>
                          <td>{val?.username}</td>
                          <td>{val?.amount}</td>
                          <td>{val?.income_type}</td>
                          <td>{val?.transaction_type}</td>
                          <td>{dateformat(val?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                        </tr>
                      );
                    })
                    }
                  </tbody>
                </table>
                {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  pageCount={totalData / 10}
                  previousLabel="<"
                />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminFundHistory;
