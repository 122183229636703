import React, { useEffect, useState } from "react";
import "./Assets/css/style.scss";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Outlet, } from "react-router-dom";
import Index from "./Public/Index";
import Header from "./Public/Header";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from "./Public/Login";
import Table from "./Public/Table";
import Form from "./Public/Form";
import Users from "./Public/Users";
import News from "./Public/News";
import WorkingPopup from "./Public/WorkingPopup";
import SiteIncome from "./Public/SiteIncome";
import WithdrawRequest from "./Public/WithdrawRequest";
import ApprovedWithdraw from "./Public/ApprovedWithdraw";
import PendingWithdraw from "./Public/PendingWithdraw";
import RejectedWithdraw from "./Public/RejectedWithdraw";
import AdminPendingwithdraw from "./Public/AdminPendingWithdraw";
import SolvedTickets from "./Public/SolvedTickets";
import SendDeposit from "./Public/SendDeposit";
import SendCredit from "./Public/SendCredit";
import FundHistory from "./Public/FundHistory";
import PrivateRoutes from "./config/PrivateRoutes";
import ActiveUsers from "./Public/ActiveUsers";
import InActiveUsers from "./Public/InActiveUsers";
import PendingTickets from "./Public/PendingTickets";
import ViewUser from "./Public/ViewUser";
import Income from "./Public/Income";
import IncomeByType from "./Public/IncomeByType";
import ROIHistory from "./Public/RoiHistory";
import BlockedUsers from "./Public/BlockedUsers";
import UserPopupImage from "./Public/UserPopupImage";
import WithdrawSettings from "./Public/WithdrawSettings";
import CommissionPackge from "./Public/CommissionPackage";
import PriceSettings from "./Public/PriceSettings";
import BusinessHistory from "./Public/BusinessHistory";
import RankedUsers from "./Public/TotalRankedUsers";
import OrderDetails from "./Public/OrderDetails";
import ApprovedOrderDetails from "./Public/ApprovedOrderDetails";
import RejectOrderDetails from "./Public/RejectOrderDetails";
import UsersTodayActivate from "./Public/UsersTodayActivate";
import RankedUsersList from "./Public/RankedUsersList";
import AchieverUsersList from "./Public/AchieverUsersList";
import DownLevel from "./Public/DownLevel";
import DownLevelBusiness from "./Public/DownLevelBusiness";
import UsersByBusiness from "./Public/UsersByBusiness";
import ViewLegBusiness from "./Public/ViewLegBusiness";
import AlmateryUsers from "./Public/AlmateryUsers";
import StakeHistory from "./Public/AllStakeHistory";
import UnstakeHistory from "./Public/AllUnstakeHistory";
import ArchieversHistory from "./Public/ArchieversHistory";
import CoreArchieversHistory from "./Public/CoreArchieversHistory";
import CreditDebit from "./Public/CreditDebit";
import BuyDetails from "./Public/buyDetails";
import SellOrderDetails from "./Public/SellOrderDetails";
import AdminFundHistory from "./Public/AdminFundHistory";
import AllCoreIncomeUsers from "./Public/AllCoreIncomeUsers";

function App() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  function Loginlayout() {
    return (
      <>
        <div className="">
          {/* <Header /> */}
          <Outlet />
        </div>
      </>
    );
  }
  function MainLayout() {
    return (
      <>
        <div className="">
          <Header />
          <Outlet />
          {/* <Footer/> */}
        </div>
      </>
    );
  }





  return (
    <>
      <Router>
        <Routes>
          <Route element={<Loginlayout />}>
            <Route path="/*" element={<Login />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route element={<MainLayout />}>
              <Route path="/dashboard" element={<Index pageTitle={`Dashboard`} />} />
              <Route path="/table" element={<Table />} />
              <Route path="/form" element={<Form />} />
              <Route path="/user" element={<Users />} />
              <Route path="/today-activate" element={<UsersTodayActivate />} />

              <Route path="/paidUsers" element={<ActiveUsers />} />
              <Route path="/inactiveUsers" element={<InActiveUsers />} />
              <Route path="/blockedUsers" element={<BlockedUsers />} />
              <Route path="/usersByRank" element={<RankedUsers />} />
              <Route path="/allRankedUsers/:rank" element={<RankedUsersList />} />
              <Route path="/acheiverUsers" element={<AchieverUsersList />} />
              <Route path="/adminTrxHistory" element={<AdminFundHistory />} />


              <Route path="/news" element={<News />} />
              <Route path="/popup_upload" element={<UserPopupImage />} />
              <Route path="/withdrawsetting" element={<WithdrawSettings />} />
              <Route path="/price-setting" element={<PriceSettings />} />

              <Route path="/comissionpackage" element={<CommissionPackge />} />
              <Route path="/CurrentUserPopup" element={<WorkingPopup />} />
              <Route path="/roiList" element={<ROIHistory />} />
              <Route path="/WithdrawSettings" element={<SiteIncome />} />
              <Route path="/BusinessHistory" element={<BusinessHistory />} />
              <Route path="/RankedUsers" element={<RankedUsers />} />
              <Route path="/allincomes" element={<Income />} />
              <Route path="/income/:type" element={<IncomeByType />} />
              <Route path="/Withdraw" element={<WithdrawRequest />} />
              <Route path="/Approved" element={<ApprovedWithdraw />} />
              <Route path="/Pending" element={<PendingWithdraw />} />
              <Route path="/Rejected" element={<RejectedWithdraw />} />
              <Route path="/ordersDetails" element={<OrderDetails />} />
              <Route path="/sellOrdersDetails" element={<SellOrderDetails />} />
              <Route path="/approvedOrdersDetails" element={<ApprovedOrderDetails />} />
              <Route path="/rejectOrderDetails" element={<RejectOrderDetails />} />
              <Route path="/buyhistory" element={<BuyDetails />} />

              <Route path="/adminpending" element={<AdminPendingwithdraw />} />
              <Route path="/pending-tickets" element={<PendingTickets />} />
              {/* <Route path="/solved-ticket" element={<ComposeMail />} /> */}
              <Route path="/solved-ticket" element={<SolvedTickets />} />
              <Route path="/SendWallet" element={<SendDeposit />} />
              <Route path="/SendIncomeWallet" element={<SendCredit />} />
              <Route path="/fund_history" element={<FundHistory />} />
              <Route path="/view-user/:username" element={<ViewUser />} />
              <Route path="/down-level" element={<DownLevel />} />
              <Route path="/down-level-business-report/:username/:level" element={<DownLevelBusiness />} />
              <Route path="/users-business" element={<UsersByBusiness />} />
              <Route path="/almatery-users" element={<AlmateryUsers />} />
              <Route path="/stake-history" element={<StakeHistory />} />
              <Route path="/unstake-history" element={<UnstakeHistory />} />
              <Route path="/archiever-history" element={<ArchieversHistory />} />
              <Route path="/corearchiever-history/:level" element={<CoreArchieversHistory />} />
              <Route path="/corearchiever-history/:level" element={<CoreArchieversHistory />} />
              <Route path="/corearchiever-history/:level" element={<CoreArchieversHistory />} />
              <Route path="/leg-business/:username" element={<ViewLegBusiness />} />
              <Route path="/credit-debit" element={<CreditDebit />} />
              <Route path="/coreusers-history" element={<AllCoreIncomeUsers />} />
            </Route>
          </Route>
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
